<template>
  <div>
    <b-row><b-col>
      <b-nav
        card-header
        pills
        class="m-0"
      >
        <b-nav-item
          v-if="$route.meta.moduleOfTab !== 2"
          :to="{ name: $route.meta.typesTabRoute }"
          exact
          exact-active-class="sub-tab-nav"
          :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >
          Types
        </b-nav-item>
        <b-nav-item
          v-if="$route.meta.moduleOfTab === 2"
          :to="{ name: $route.meta.rolesTabRoute }"
          exact
          exact-active-class="sub-tab-nav"
          :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >
          Roles
        </b-nav-item>
      </b-nav>
    </b-col>
    </b-row>
    <router-view :key="$route.name" />
  </div>
</template>

<script>
export default {
  name: 'ProgramCommissions',
}
</script>

<style scoped>

</style>
